@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Main{
  background-color: #005A9F;
}

.Top {
  max-width: 900px;
  min-width: 500px;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  padding-bottom: 40px;
  padding-top: 20px;
  display: -webkit-flex;
  display: flex;
}

.Bottom {
  color: black;
  width: 100%;
  font-family: 'Montserrat',serif;
  font-weight: 800;
  text-align: left;
}

.right {
  font-family: 'Montserrat',serif;
  font-weight: 400;
  color: white;
  text-align: left;
  transition: width 1s, height 1s;
  padding-left: 10px;
}

.right p{
  padding-top: 40px;
}

.MenuTop{
  transition: width 1s, height 1s;
}

.Menu {
  display: -webkit-inline-flex;
  display: inline-flex;
  width: 100%;
  margin-top: 20px;
  transition: width 1s, height 1s, padding 1s, font-size 0.5s;
}

.active{
  /*box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19);*/
}

.row {
  transition: width 1s, height 1s;
  margin-right: 4px;
}

.header{
  transition: height 1s, padding 1s, font-size 1s;
  background-color: white;
  display: inline-block;
  width: 100%;
}

.item {
  color: black;
  border: solid 0px #005A9F;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #e0ad02;
  font-family: 'Montserrat', sans-serif;
  transition: width 1s, height 1s;
  font-weight: 900;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
}


body {
  background-color:#ffffff;
}

.it4{
  background-color:#005A9F;
  color: white;
  cursor:auto;
}

.item p{
  text-shadow: 0px 1px 1px rgb(60 60 60 / 30%);
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  margin: 0.2em;
  pointer-events: none;
}

.item-active:hover{
  color: white;
  /*background-color: #C9083B;*/
}

.hov {
  transform: scale(2,2);
  -ms-transform: scale(2,2);
  -webkit-transform:scale(2,2);
  -moz-transform: scale(2,2);
  -o-transform: scale(2,2);
  /*border: solid 1px #061318;*/
  box-shadow: 0 1x 2px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19);
}

.act {
  transform: scale(1.05,1.05);
  -ms-transform: scale(1.05,1.05);
  -webkit-transform:scale(1.05,1.05);
  -moz-transform: scale(1.05,1.05);
  -o-transform: scale(1.05,1.05);
  border: solid 1px #061318;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19);
}

.Main a {
  color: #f8fc24;
}

.footer{
  background-color: white;
  height: 100%;
  display: inline-block;
  transition: height 1s, padding 1s, font-size 1s;
}

.footText{
  display: inline-block;
  width: 90%;
  max-width: 980px;
  text-align: left;
}

.footText li{
  margin-bottom: 0.5em;
}

.header h1{
  margin: 0px;
  text-align: left;
  width: 50%;
  display: inline-block;

}

.header p{
  width: 15%;
  margin: 0px;
  display: inline-block;
}

.fade-in {
  animation: fadeIn 2s ease-in;
  -webkit-animation: fadeIn 2s ease-in;
  -moz-animation: fadeIn 2s ease-in;
  -o-animation: fadeIn 2s ease-in;
  -ms-animation: fadeIn 2s ease-in;
  }
  @keyframes fadeIn {
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
  }

  .dis{
    opacity: 0;
  }

  .fade-in2 {
    animation: fadeIn 1s ease-in, 2s blinker 1s linear infinite;
    -webkit-animation: fadeIn 1s ease-in, 2s blinker 1s linear infinite;
    -moz-animation: fadeIn 1s ease-in, 2s blinker 1s linear infinite;
    -o-animation: fadeIn 1s ease-in, 2s blinker 1s linear infinite;
    -ms-animation: fadeIn 1s ease-in, 2s blinker 1s linear infinite;
    }
    @keyframes fadeIn {
      0% { opacity:0; }
      66% { opacity:0; }
      100% { opacity:1; }
    }
    
    @-webkit-keyframes fadeIn {
      0% { opacity:0; }
      66% { opacity:0; }
      100% { opacity:1; }
    }

    .next p{
      text-shadow: 0px 1px 1px rgb(60 60 60 / 30%);
      background-clip: text;
      -moz-background-clip: text;
      -webkit-background-clip: text;
      margin: 0.2em;
      pointer-events: none;
    }

    .next{
      pointer-events: visible;
      cursor: pointer;
      color: white;
      font-family: 'Montserrat',serif;
      font-weight: 400;
      font-size: 2em;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 20px;
      margin-bottom: 10px;
    }

    .next:hover{
      color: yellow;
    }

    .blink {
      -webkit-animation: blinker 1s linear infinite;
              animation: blinker 1s linear infinite;
      -webkit-animation-delay: 1s;
              animation-delay: 1s;
    }
    
    @-webkit-keyframes blinker {
      50% {
        opacity: 0.1;
      }
    }
    
    @keyframes blinker {
      50% {
        opacity: 0.1;
      }
    }
